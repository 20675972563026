import { Avatar, Badge, Col, Collapse, ConfigProvider, Input, Menu, Row, Select, Space, Switch, Tooltip, Typography, theme } from "antd"
import bg from '@/assets/bg2.png';
import { useNavigate, useLocation } from 'react-router-dom';

import { useTranslation, Trans, Translation } from 'react-i18next'
import i18n from '@/i18n/config/i18n';
import { createContext, Dispatch, Suspense, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { RightOutlined, SearchOutlined, DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import './Dashboard.scss'
import DashboardHeader from "@/components/DashboardHeader/DashboardHeader";
import ToolBarHeader from "@/components/ToolBarHeader";
import DashboardFooter from "@/components/DashboardFooter/DashboardFooter";
import BGCard from '@/assets/BG-rounded.png'
// import AECard from '@/assets/AE-rounded.png'
import AECard from '@/assets/SB-rounded.png'
import EBCard from '@/assets/EB-rounded.png'
import DGCard from '@/assets/DG-rounded.png'
import BGIcon from '@/assets/BG-icon.png'
import EBIcon from '@/assets/EB-icon.png';
import ABIcon from '@/assets/logo_AB.png';
import DGIcon from '@/assets/DG-icon.png';
import AEIcon from '@/assets/SB-icon.svg';
import hotIcon from '@/assets/hot-icon.svg';
import starIcon from '@/assets/star.svg';
import tables from "@/mocks/tables";
import { sort } from "semver";
import { t, use } from "i18next";
import cx from 'classnames';
import AceToken from "@/components/AceToken";
import { useQuery, useSuspenseQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { getAceHackers, getAceToken, getPlatforms, getTableList, getTablePredication, ping, TAcePlatform } from "@/util/fetches";
import Spinner, { SpinnerLoading } from "@/components/Spinner";
import BaccaratRoad, { BigEyeBoy, BigRoad, CockroachRoad, PearlRoadMap, SmallRoad } from "@/components/BaccaratRoad";
import { UrlParamsProvider, useUrlParams } from './UrlParamsContext'; // adjust the path as necessary

import { userSlice } from "@/store";
import { useDispatch } from "react-redux";
import NiceModal from "@ebay/nice-modal-react";
import NotificationModal from "@/components/Modal/NotificationModal";
import { testRoads } from "@/components/BaccaratRoad/util";
import router from "@/router/router";
type State = {
    platform: string | null;
    hacker: string | null;
    table: string | null;
    active: boolean;
};
const initialState = {
    platform: null,
    hacker: null,
    table: null,
    active: false
};
const DashboardContext = createContext<State>(initialState);
const DashboardDispatchContext = createContext<Dispatch<any> | null>(null);



const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_PLATFORM':
            return { ...initialState, platform: action.payload };
        case 'SET_HACKER':
            return { ...state, hacker: action.payload };
        case 'SET_TABLE':
            return { ...state, table: action.payload };
        case 'SET_ACTIVE':
            return { ...state, active: action.payload };
        default:
            return state;
    }
};

const Dashboard = () => {

    useQuery({
        queryKey: ['ping'],
        queryFn: ping,
        retry: 5,
        retryDelay: 1000,
        refetchOnWindowFocus: false,
        throwOnError: true,
    });




    const [state, dispatch] = useReducer(reducer, initialState);

    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const initialPlatform = queryParams.get('platform');
        const initialHacker = queryParams.get('hacker');
        const initialTable = queryParams.get('table');
        const active = queryParams.get('active');

        if (initialPlatform) {
            dispatch({ type: 'SET_PLATFORM', payload: initialPlatform });
        }
        if (initialHacker) {
            dispatch({ type: 'SET_HACKER', payload: initialHacker });
        }
        if (initialTable) {
            dispatch({ type: 'SET_TABLE', payload: initialTable });
        }
        if (active !== undefined) {
            dispatch({ type: 'SET_ACTIVE', payload: active === 'true' })
        }
    }, [dispatch, location.search]);


    // 加载组件，用于翻译，但不限于放在哪一个组件。
    let { t } = useTranslation(); // 加载组件

    const { platform } = state;

    const queryClient = useQueryClient()

    useEffect(() => {

        queryClient.prefetchQuery({
            queryKey: ['api/ace/platforms'],
            queryFn: getPlatforms
        })
        queryClient.prefetchQuery({
            queryKey: ['api/ace/hackers'],
            queryFn: getAceHackers
        })

    }, [queryClient])
    return (<ConfigProvider
        theme={{
            // 1. Use dark algorithm
            algorithm: theme.darkAlgorithm,

            // 2. Combine dark algorithm and compact algorithm
            // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
        }}
    >

        <DashboardContext.Provider value={state}>
            <DashboardDispatchContext.Provider value={dispatch}>
                <UrlParamsProvider>

                    <DashboardHeader />
                    <ToolBarHeader />
                    <div id="dashboardBody" className="pt-2">

                        <Row className="dashboard-center-width m-auto h-100">
                            {/* platforms  */}
                            <Col className="left-dash dashboard-sidebar-D d-none d-md-inline-flex align-items-stretch">
                                <AcePlatform />
                            </Col>

                            {/* platforms */}
                            <Col className="dashboard-content-D p-0 p-md-4">
                                <Col span={24} className="d-md-none">
                                    <div className="dashboard-menu-M w-100 d-flex d-md-none">
                                        {platform && <div className="dashboard-menu-M__item">{renderPlatformRoundedCard(platform)}</div>}
                                        <Suspense fallback={<DashboardSpinner />}>
                                            <PlatformListMobile />
                                        </Suspense>
                                    </div>
                                </Col>
                                <AceHackers />
                            </Col>

                        </Row>
                    </div>
                    <DashboardFooter />
                </UrlParamsProvider>

            </DashboardDispatchContext.Provider>
        </DashboardContext.Provider>
    </ConfigProvider >)

}

const PlatformListMobile = () => {
    const { data: platforms, isFetching, isLoading } = useSuspenseQuery({
        queryFn: getPlatforms,
        queryKey: ['api/ace/platforms'],
    })

    const dispatch = useContext(DashboardDispatchContext);

    const { platform } = useContext(DashboardContext);

    const queryClient = useQueryClient()

    const onClick = useCallback((item) =>
        () => {
            if (item.status === 'Inactive') {
                return;
            }

            console.log("SET PLATFORM", item.id, item.name)
            if (dispatch) {
                dispatch({ type: 'SET_PLATFORM', payload: item.id })
            }

        }, [dispatch]);

    return (<>{

        processPlatforms(platforms).map((item, index) => (
            <div onClick={onClick(item)} className={cx("dashboard-menu-M__item", item.id === platform && 'selected', item.status === 'Inactive' && 'inactive')} key={index}>
                <div>
                    <img width={40} src={item.icon} alt={item.name} />
                </div>
                <span className="menu-text-M">{item.name}</span>
            </div>
        ))}
    </>);
}

const { Panel } = Collapse;


const { Title, Text } = Typography;

const renderPlatformRoundedCard = (platform: string) => {
    return (
        <>
            {platform === "EB" && <img className="img-fluid" src={EBCard} alt="EBET" />}
            {platform === "AB" && <img className="img-fluid" src={EBCard} alt="ALLBET" />}
            {platform === "BG" && <img className="img-fluid" src={BGCard} alt="Big Gaming" />}
            {platform === "AE" && <img className="img-fluid" src={AECard} alt="AE Casino" />}
            {platform === "DG" && <img className="img-fluid" src={DGCard} alt="Dream Gaming" />}
        </>
    )
}

const AcePlatform = () => {
    const platform = useContext(DashboardContext).platform;
    const { t } = useTranslation()

    return (
        <div className="w-100 h-100 min-h-[250px] flex flex-column">
            <Row justify="center" className="mt-4 mb-5">
                <Col span={18}>
                    <Row>
                        <Col span={3}>
                            <img width={15} height={15} src={starIcon} alt="StarIcon" />
                        </Col>
                        <Col span={21}>
                            <Title level={5}>{t(`TRUSTED BY WORLDCLASS PLAYER`)}</Title>
                        </Col>
                    </Row>
                    <Row justify="center" className="mt-3 mb-1">
                        <Col>
                            {renderPlatformRoundedCard(platform)}
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col className="grid text-center">
                            <Text style={{ color: 'rgb(239, 154, 35)' }}>{platform}</Text>
                            <Text style={{ color: 'rgb(227, 210, 255)' }}>{t('BACCARAT FORMULA')}</Text>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Suspense fallback={<DashboardSpinner />}>
                <PlatformList />
            </Suspense>
        </div>
    );
}

const DashboardSpinner = () => (<div className="w-full p-4 flex justify-center items-center">
    <SpinnerLoading color={"white"} style={{
        width: 50,
        height: 50,
    }} /></div>)


const PlatformList = () => {
    const platform = useContext(DashboardContext).platform;

    const dispatch = useContext(DashboardDispatchContext);


    const { data: platforms } = useSuspenseQuery({
        queryFn: getPlatforms,
        queryKey: ['api/ace/platforms'],
    })


    const { setPlatform, setHacker, setTable } = useUrlParams();

    const navigate = useNavigate();

    return (<Menu mode="vertical"
        className="dashboard-platform-list flex-1 self-end"
        style={{ width: '100%' }}
        onSelect={(item) => {
            console.log("SET PLATFORM", item.key)
            setPlatform(item.key)
            // make the url change e.g. get rid of hacker/table
            router.navigate('/dashboard?platform=' + item.key)

        }}
        selectedKeys={platform ? [platform] : []}
    >
        {processPlatforms(platforms).map((item, index) => (
            <Menu.Item disabled={item.status === 'Inactive'} key={item.id} icon={<img width="20" src={item.icon} alt={item.alt} />} style={{ justifyContent: 'space-between' }}>
                {item.name}
                <RightOutlined />
            </Menu.Item>
        ))}
    </Menu>)
}

const AceHackers = () => {
    const { hacker, platform, table } = useContext(DashboardContext);
    const dispatch = useContext(DashboardDispatchContext);

    const { setPlatform, setHacker, setTable } = useUrlParams();

    const [activeKey1, setActiveKey1] = useState(['1']);
    const [activeKey, setActiveKey] = useState(['1']);


    return (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-2 mt-md-0" hidden={!platform}>
            <Col span={24} lg={8} className="mb-2">
                <Collapse
                    activeKey={platform ? activeKey1 : []}
                    onChange={(key) => {
                        setActiveKey1(key.length >= 1 ? [key[0]] : [])
                    }}
                    bordered={false} className="collapse-card-custom">
                    <Panel key="1" header={t(`1. Choose your Ace Hacker`)} className="site-collapse-custom-panel">
                        <div className="list-formula mt-3">
                            <Suspense fallback={<DashboardSpinner />}>
                                <HackerList />
                            </Suspense>
                        </div>
                    </Panel>
                </Collapse>
            </Col>
            {platform && hacker &&
                <Col span={24} lg={16} hidden={!(platform && hacker)}>
                    <Collapse
                        bordered={false}
                        activeKey={activeKey}
                        onChange={(key) => {
                            setActiveKey(key.length >= 1 ? [key[0]] : [])
                        }}
                        // defaultActiveKey={hacker ? ['1'] : []}
                        expandIconPosition="start"
                        className="collapse-card-custom">
                        <Panel

                            header={

                                <div>2. {t("Choose your Table")}

                                    <span className="ml-4 text-white/[.6]">{t("AI系统会实时采集您的下注记录")}</span>
                                    {/* <span className="ml-4 text-white/[.6]">{t("* %Recall trained on 24H dataset")}</span> */}
                                </div>

                            } key="1" className="site-collapse-custom-panel">

                            <Suspense fallback={<DashboardSpinner />}>
                                <TableSelection />
                            </Suspense>
                        </Panel>
                    </Collapse>
                </Col>
            }
            <Suspense fallback={<DashboardSpinner />}>
                {platform && hacker && table && <CardComponent />}
            </Suspense>
        </Row>
    );
}


const HackerList = () => {
    const { data: hackers, isFetching, isLoading } = useSuspenseQuery({
        queryFn: getAceHackers,
        queryKey: ['api/ace/hackers'],
    })

    // console.log("hackers", hackers);

    const { hacker, platform } = useContext(DashboardContext);
    const dispatch = useContext(DashboardDispatchContext)
    const { setPlatform, setHacker, setTable } = useUrlParams();


    /*
    [
        { id: "#001", name: "教授", status: "在线" },
        { id: "#106", name: "科技达人", status: "在线" },
        { id: "#787", name: "赛博大师", status: "在线" },
        { id: "#626", name: "数字程序员", status: "在线" },
        { id: "#567", name: "解密者", status: "在线" },
    ]
    */
    return (<>
        {hackers && hackers.map((item) => (
            <div
                key={item.id}
                onClick={() => setHacker(item.id)}
                // key={item.id} 
                className={cx("formula px-2 py-2 d-flex flex-row align-items-center cursor-pointer")}
                style={hacker === item.id ? { backgroundColor: 'rgb(02, 19, 12)' } : {}}
            >
                <Avatar className={cx(hacker === item.id ? "bg-warning" : "circle-bg-pink")} size={19}>E</Avatar>
                <span className="px-2 py-1 formula-item">{item.id + ": " + t(item.name)}</span>
                <span className="ms-auto fw-bold text-green text-smaller">{t(item.status)}</span>
                <Tooltip title={<><div>{t('Training Count') + ": " + item.trainingCount}</div><div>{t(item.description)}</div></>} key={item.id} placement="left">
                    <InfoCircleOutlined className="ml-2" style={{ fontSize: 16 }} />
                </Tooltip>

            </div>
        ))}
    </>)
}


const TableSelection = () => {

    // const [selectedTable, setSelectedTable] = useState<string | null>(null);
    const {
        table: selectedTable
    } = useContext(DashboardContext);

    const {
        platform,
        hacker,
    } = useContext(DashboardContext);
    const dispatch = useContext(DashboardDispatchContext);
    const { setPlatform, setHacker, setTable } = useUrlParams();

    const [searchTerm, setSearchTerm] = useState('');
    const [sortOrder, setSortOrder] = useState('winRateHighLow');

    const { data: initialTables, isFetching, isLoading } = useSuspenseQuery({
        queryKey: ['api/ace/table', { platform, hacker }],
        queryFn: async () => {
            return await getTableList({ platform, hacker });
        },
    })

    // const [initialTables, setTables] = useState(tables);
    const filteredAndSortedTables = useMemo(() => {
        const filteredTables = (initialTables || []).filter(table =>
            table.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        switch (sortOrder) {
            case 'winRateHighLow':
                return filteredTables.sort((a, b) => b.winRate - a.winRate);
            case 'winRateLowHigh':
                return filteredTables.sort((a, b) => a.winRate - b.winRate);
            case 'nameAZ':
                return filteredTables.sort((a, b) => a.name.localeCompare(b.name));
            case 'nameZA':
                return filteredTables.sort((a, b) => b.name.localeCompare(a.name));
            default:
                return filteredTables;
        }
    }, [searchTerm, sortOrder, initialTables]);

    const handleSearchChange = event => {
        setSearchTerm(event.target.value);
    };

    const handleSortChange = value => {
        setSortOrder(value);
    };

    const [activeKey, setActiveKey] = useState(['1']);
    return (
        <>

            <div className="d-flex w-100 mb-3 filter-container">
                <div className="d-flex align-items-baseline">
                    <Typography.Text className="search-text-color mr-2">{t(`Sort by:`)} </Typography.Text>
                    <Select
                        defaultValue={sortOrder}
                        onChange={handleSortChange}
                        style={{ width: 200 }}
                        suffixIcon={<RightOutlined />}
                        className="me-3 form-input_select-container">
                        <Select.Option value="winRateHighLow">{t(`Win Rate High to Low`)}</Select.Option>
                        <Select.Option value="winRateLowHigh">{t(`Win Rate Low to High`)}</Select.Option>
                        <Select.Option value="nameAZ">{t(`Name A-Z`)}</Select.Option>
                        <Select.Option value="nameZA">{t(`Name Z-A`)}</Select.Option>
                        {/* Add more sorting options here */}
                    </Select>
                </div>
                <div className="w-100 d-flex justify-content-end ps-2">
                    <Input
                        prefix={<SearchOutlined />}
                        placeholder={t("Search")}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="w-100 input-search search-text-color" />
                </div>
            </div>
            <Row
                // gutter={[16, 16]}
                className="flex-nowrap overflow-auto pb-3 w-auto overflow-auto list-table-dashboard">
                {
                    (!filteredAndSortedTables || filteredAndSortedTables.length === 0) && <div className="w-100 pt-2 text-center text-white">{t('Prediction in Progress...')}</div>

                }
                {/* Map through your tables data here */}
                {filteredAndSortedTables.map((table, index) => (
                    <Col onClick={() => setTable(table.name)} key={index} flex="none" className="me-3">
                        <div
                            className={cx("cursor-pointer table-card d-flex flex-column align-items-center", selectedTable === table.name && "table-card-selected")}>
                            <div className="fw-bold formula-item-selected text-center pb-2 pt-3 text-smaller w-100 px-2 min-h-20">
                                <p className="mb-0">{t(`BACCARAT`)}</p>
                                {table.name}
                            </div>
                            <svg width="80" height="80" className="donutchart">
                                <circle r="34.5" cx="40" cy="40" transform="rotate(-90 40,40)" className="donutchart-track" style={{ strokeWidth: 11 }}></circle>
                                <circle r="34.5" cx="40" cy="40" transform="rotate(-90 40,40)" className="donutchart-indicator"
                                    style={{ strokeWidth: 11, strokeDasharray: calculateStrokeDasharray(table.winRate), stroke: chooseStrokeColor(table.winRate) }}></circle>
                                <text className="donutchart-text text-white" x="42" y="47" style={{ textAnchor: 'middle' }}>
                                    <tspan className="donutchart-text-val fw-bold text-white">{table.winRate}</tspan>
                                    <tspan className="donutchart-text-percent fw-bold text-white">%</tspan>
                                </text>
                            </svg>
                            <div className="card-body py-2 w-100 text-center text-white">{t(`AI Win Rate`)}</div>
                        </div>
                        {selectedTable === table.name && <SelectedButton />}
                    </Col>
                ))}
            </Row>
        </>

    );
};


function calculateStrokeDasharray(winRate) {
    const circumference = 2 * Math.PI * 34.5; // 34.5 is the radius of the circle
    const filledPortion = (winRate / 100) * circumference;
    return `${filledPortion.toFixed(3)}, ${circumference.toFixed(3)}`;
}


function calculateResultStrokeDasharray(winRate) {
    const circumference = 2 * Math.PI * 46; // 34.5 is the radius of the circle
    const filledPortion = (winRate / 100) * circumference;
    return `${filledPortion.toFixed(3)}, ${circumference.toFixed(3)}`;
}

function chooseStrokeColor(winRate) {
    if (winRate >= 90) {
        return "rgb(117, 238, 73)"; // Bright green for high performance
    } else if (winRate >= 75) {
        return "rgb(190, 238, 74)"; // Lighter green for moderate performance
    } else {
        return "rgb(239, 233, 73)"; // Yellow for lower performance
    }
}


const SelectedButton = () => {
    return (<button type="button" className="ant-btn ant-btn-default button-select-table-card mt-2">
        <div className="d-flex flex-column fw-bold text-smaller align-items-center">
            <span>Selected</span>
            <DownOutlined />
        </div>
    </button>)
}


const CardComponent = () => {
    const { platform, hacker, table, active } = useContext(DashboardContext);
    const [activeKey, setActiveKey] = useState(['1']);

    const [arrow, setArrow] = useState('Show');
    const dispatch = useDispatch()
    const queryClient = useQueryClient();
    const { setPlatform, setHacker, setTable, setActive } = useUrlParams();

    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }

        if (arrow === 'Show') {
            return true;
        }

        return {
            pointAtCenter: true,
        };
    }, [arrow]);

    const text = <span>{t('Activating prediction will result in continuous deductions of Ace Token.')}</span>;



    const tokenMut = useMutation({
        mutationFn: getAceToken,
        onSuccess(data, variables, context) {
            // console.log('get ace token result', data);
            dispatch(userSlice.actions.setToken(data));
        },
    })

    const { data: prediction, isLoading, refetch } = useSuspenseQuery({
        queryKey: ['/api/ace/table/predict', platform, hacker, table],
        queryFn: async () => {
            if (!active) {
                console.log("not active, not fetching prediction");
                return {
                    roads: [],
                    monteCarlo: [],
                    winRate: 0,
                    result: '',
                }
            }
            const res = await getTablePredication({ platform, hacker, table });
            // console.log("get table prediction result", res);
            if (res.success) {
                return res.data;
            }
            NiceModal.show(NotificationModal, {
                title: res.message || 'Not Enough Token',
                seconds: 3,
                type: 'error'
            });

            router.navigate('/lobby');
            setActive(false);
            return {
                roads: [],
                monteCarlo: [],
                winRate: 0,
                result: '',
            }
        },

    });

    useEffect(() => {
        tokenMut.mutate();
        // if (prediction && !prediction.success) {
        //     setActive(false);
        // }
    }, [prediction]);



    const [loading, setLoading] = useState(false);


    const refetchPrediction = useCallback(async () => {
        setLoading(true);
        await refetch();
        setTimeout(() => setLoading(false), 2500);
    }, [refetch]);

    useEffect(() => {
        if (active) {
            const intervalId = setInterval(refetchPrediction, 3000); // Refetch every 3 seconds

            return () => clearInterval(intervalId); // Cleanup the interval on component unmount or when 'active' changes to false
        }
    }, [active, platform, hacker, table, refetchPrediction]);


    const roads = prediction?.roads || [];
    const monteCarlo = prediction?.monteCarlo || [];
    const winRate = prediction?.winRate || 0;
    const result = prediction?.result || '';
    let bankerRate = 'X';
    let playerRate = 'Y';
    let tieRate = 'Z';

    if (monteCarlo && monteCarlo.length == 3) {
        const sum = monteCarlo.reduce((a, b) => a + b, 0);
        bankerRate = (monteCarlo[0] / sum * 100).toFixed(0);
        playerRate = (monteCarlo[1] / sum * 100).toFixed(0);
        tieRate = (monteCarlo[2] / sum * 100).toFixed(0);
    }


    const show = platform && hacker && table;
    function formatPredictionResult(result: any) {
        if (result === 'B') {
            return 'Banker';
        }
        if (result === 'P') {
            return 'Player';
        }
        if (result === 'T') {
            return 'Tie';
        }
        return '';
    }
    // The text to split
    const waveText = t('Calculating');
    // const waveText = t('AI正在采集下注记录')
    // Split the text into an array of characters
    const characters = waveText.split('');

    useEffect(() => {

        if (active) {
            refetch();
        }
    }, [active, refetch])

    const [isFixed, setIsFixed] = useState(false);
    const [initialTop, setInitialTop] = useState(0);

    const updateInitialTop = () => {
        const scrollSpyDiv = document.getElementsByClassName('next-round-prediction')[0];
        if (scrollSpyDiv) {
            const rect = scrollSpyDiv.getBoundingClientRect();
            setInitialTop(rect.top + window.pageYOffset - 100);
        }
    };

    useEffect(() => {
        const scrollSpyDiv = document.getElementsByClassName('next-round-prediction')[0];
        updateInitialTop(); // Set initial top position on mount

        const handleScroll = () => {
            if (!scrollSpyDiv) return;

            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (scrollTop > initialTop) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        const observer = new ResizeObserver(() => {
            window.requestAnimationFrame((): void | undefined => {
                updateInitialTop();
            });

        });

        if (scrollSpyDiv) {
            observer.observe(scrollSpyDiv);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (scrollSpyDiv) {
                observer.unobserve(scrollSpyDiv);
            }
        };
    }, [initialTop]);

    return (
        <Col hidden={!(show)} span={24} className={cx("mt-1 mt-md-4")}>
            <Collapse
                activeKey={show ? activeKey : []}
                // onChange={(key) => {
                //     setActiveKey(key.length >= 1 ? [key[0]] : [])
                // }}
                expandIcon={() => null}
                bordered={false} className="collapse-card-custom cardTypeTwo px-3 py-2">
                <Panel header={
                    <div className="flex justify-between">
                        <Row className="flex-nowrap overflow-hidden justify-center items-center">
                            {/* <Row className=""> */}
                            <Row
                                // className="flex flex-col min-w-[140px]"
                                className="flex flex-col"
                                style={{
                                    whiteSpace: 'nowrap',
                                }}
                            // align="start" 
                            // gutter={{ xs: 8, sm: 16 }}
                            >
                                <Typography.Text className="collapse-header sm:hidden">{t(`BACCARAT`)} {table}</Typography.Text>
                                <Typography.Text className="collapse-header hidden sm:block">{table}</Typography.Text>
                                <Col className="text-green collapse-header fw-bold sm:hidden">{t(`Win Rates`)} {winRate}%</Col>
                                <Col className="text-green collapse-header fw-bold hidden sm:block">{t(`WR`)} {winRate}%</Col>
                            </Row>
                            <Row className="ml-4 flex items-center justify-center wave-text flex-nowrap">
                                {loading &&

                                    <>
                                        <div className="waving-loading mr-2">
                                            {characters.map((char, index) => (
                                                <span key={index}>{char}</span>
                                            ))}
                                        </div>
                                        <div className="">
                                            <Spinner style={{ height: 48, width: 48 }} />
                                        </div>
                                    </>
                                }
                            </Row>
                        </Row>
                        <Row className="flex items-center gap-2.5 sm:grid">
                            <AceToken />
                            <div className="flex justify-center items-center sm:justify-end">
                                <Switch
                                    value={active}
                                    style={{ backgroundColor: active ? 'rgb(234,151,35)' : 'gray' }}
                                    onChange={(checked) => {
                                        setActive(checked)
                                    }}
                                    className="custom-switch mx-[0.5rem] sm:mx-[0px]" checkedChildren={t("Active")} unCheckedChildren={t("Inactive")} defaultChecked
                                />
                                <Tooltip placement="bottomRight" title={text} arrow={mergedArrow}>
                                    <InfoCircleOutlined className="sm:hidden" style={{ fontSize: 25 }} />
                                </Tooltip>
                            </div>
                        </Row>
                    </div>
                } key="1">
                    <Row gutter={[16, 16]} className={cx("flex justify-between mb-3 prediction-collapse", !active && 'inactive')}>
                        <Col span={24} xl={12} className="table-card-selected-bg">
                            <span className="formula-item-selected">{t(`Next Round Prediction`)}</span>
                            <Row gutter={8} className="flex gap-4 items-center justify-center">
                                {/* {isFixed && <div className="fixed-placeholder"></div>} */}
                                <Col className={cx("next-round-prediction", isFixed && active && "fixed")} span={24} sm={11} md={10} lg={10}>
                                    <div className="border-container mt-3 prediction-circle prediction-sec-bg d-flex align-items-center justify-content-center">
                                        <Avatar size={35} className={cx("font-bold mr-3",
                                            result === "P" ? "circle-bg-blue" : result === "B" ? "circle-bg-red" : result === "T" ? "circle-bg-green" : "circle-bg-blue")
                                        } style={{ border: 'none', fontSize: 20 }}>{result}</Avatar>
                                        <div className={cx("name",
                                            result === "P" ? "name-player" : result === "B" ? "name-banker" : result === "T" ? "name-tie" : "name-player"
                                        )}>{t(formatPredictionResult(result))}</div>
                                    </div>
                                    <PredictionBar
                                        banker={monteCarlo[0]}
                                        player={monteCarlo[1]}
                                        tie={monteCarlo[2]}
                                    />
                                </Col>
                                {/* <BaccaratRoad results={roads}  /> */}


                                <div className="ml-[-16px] gap-2 ml-4 pb-4 flex-1 flex justify-center items-center">
                                    <Tooltip placement="bottomLeft" title={t('Pearl Road')} arrow={mergedArrow}>
                                        <InfoCircleOutlined style={{ fontSize: 25 }} />
                                    </Tooltip>
                                    <PearlRoadMap roads={roads} />
                                </div>
                            </Row>
                        </Col>

                        <Col span={24} xl={12} className="flex flex-column justify-center table-card-selected-bg">
                            <Row>
                                <span className="formula-item-selected mb-4">{t(`Expected Win Rate`)}</span>
                            </Row>
                            <Row className="w-full flex space-between sm:mx-[-24px] percentage-container">
                                <Col className="flex justify-center sm:pr-1" span={8}>
                                    <svg width="100" height="100" viewBox="0 0 100 100"
                                        preserveAspectRatio="xMidYMid meet" className="donutchart">
                                        <circle r="46" cx="50" cy="50" transform="rotate(-90 50,50)" className="donutchart-track" style={{ strokeWidth: 11 }}></circle>
                                        <circle r="46" cx="50" cy="50" transform="rotate(-90 50,50)" className="donutchart-indicator"
                                            style={{ strokeWidth: 11, strokeDasharray: calculateResultStrokeDasharray(bankerRate), stroke: 'rgb(255, 8, 14)' }}></circle>
                                        <text className="donutchart-text text-white" x="50" y="47" style={{ textAnchor: 'middle' }}>
                                            <tspan className="donutchart-text-val fw-bold text-white">{(bankerRate)}</tspan>
                                            <tspan className="donutchart-text-percent fw-bold text-white">%</tspan>
                                            <tspan x={50} y={70} className="donutchart-text-percent fw-bold text-uppercase">{t(`BANKER`)}</tspan>
                                        </text>
                                    </svg>
                                </Col>
                                <Col className="flex justify-center sm:pr-1" span={8}>
                                    <svg width="100" height="100" viewBox="0 0 100 100"
                                        preserveAspectRatio="xMidYMid meet" className="donutchart">
                                        <circle r="46" cx="50" cy="50" transform="rotate(-90 50,50)" className="donutchart-track" style={{ strokeWidth: 11 }}></circle>
                                        <circle r="46" cx="50" cy="50" transform="rotate(-90 50,50)" className="donutchart-indicator"
                                            style={{ strokeWidth: 11, strokeDasharray: calculateResultStrokeDasharray(playerRate), stroke: 'rgb(22, 146, 210)' }}></circle>
                                        <text className="donutchart-text text-white" x="50" y="47" style={{ textAnchor: 'middle' }}>
                                            <tspan className="donutchart-text-val fw-bold text-white">{playerRate}</tspan>
                                            <tspan className="donutchart-text-percent fw-bold text-white">%</tspan>
                                            <tspan x={50} y={70} className="donutchart-text-percent fw-bold text-uppercase">{t(`PLAYER`)}</tspan>
                                        </text>
                                    </svg>
                                </Col>
                                <Col className="flex justify-center sm:pr-1" span={8}>
                                    <svg width="100" height="100" viewBox="0 0 100 100"
                                        preserveAspectRatio="xMidYMid meet" className="donutchart">
                                        <circle r="46" cx="50" cy="50" transform="rotate(-90 50,50)" className="donutchart-track" style={{ strokeWidth: 11 }}></circle>
                                        <circle r="46" cx="50" cy="50" transform="rotate(-90 50,50)" className="donutchart-indicator"
                                            style={{ strokeWidth: 11, strokeDasharray: calculateResultStrokeDasharray(tieRate), stroke: 'rgb(35, 235, 5)' }}></circle>
                                        <text className="donutchart-text text-white" x="50" y="47" style={{ textAnchor: 'middle' }}>
                                            <tspan className="donutchart-text-val fw-bold text-white">{tieRate}</tspan>
                                            <tspan className="donutchart-text-percent fw-bold text-white">%</tspan>
                                            <tspan x={50} y={70} className="donutchart-text-percent fw-bold text-uppercase">{t(`TIE`)}</tspan>
                                        </text>
                                    </svg>
                                </Col>
                            </Row>
                            <PredictionBar
                                banker={monteCarlo[0]}
                                player={monteCarlo[1]}
                                tie={monteCarlo[2]}
                            />
                        </Col>
                        <Col span={24} className="flex flex-wrap justify-center items-center gap-4 space-between table-card-selected-bg table-card-road-maps w-full">

                            <span className="formula-item-selected w-full">{t(`Score Cards`) + ` (${t("AI已分析模型")})`}</span>
                            <Row gutter={8} className="flex gap-4 items-center justify-center">

                                <div className="ml-[-16px] gap-2 ml-4 pb-4 flex-1 flex justify-center items-center">
                                    <Tooltip placement="bottomLeft" title={t('Big Road')} arrow={mergedArrow}>
                                        <InfoCircleOutlined style={{ fontSize: 25 }} />
                                    </Tooltip>
                                    <BigRoad roads={roads} />
                                </div>
                                <div className="ml-[-16px] gap-2 ml-4 pb-4 flex-1 flex justify-center items-center">
                                    <Tooltip placement="bottomLeft" title={t('Big Eye Boy')} arrow={mergedArrow}>
                                        <InfoCircleOutlined style={{ fontSize: 25 }} />
                                    </Tooltip>
                                    <BigEyeBoy roads={roads} />
                                </div>
                                <div className="ml-[-16px] gap-2 ml-4 pb-4 flex-1 flex justify-center items-center">
                                    <Tooltip placement="bottomLeft" title={t('Small Road')} arrow={mergedArrow}>
                                        <InfoCircleOutlined style={{ fontSize: 25 }} />
                                    </Tooltip>
                                    <SmallRoad roads={roads} />
                                </div>
                                <div className="ml-[-16px] gap-2 ml-4 pb-4 flex-1 flex justify-center items-center">
                                    <Tooltip placement="bottomLeft" title={t('Cockroach Road')} arrow={mergedArrow}>
                                        <InfoCircleOutlined style={{ fontSize: 25 }} />
                                    </Tooltip>
                                    <CockroachRoad roads={roads} />
                                </div>
                            </Row>
                        </Col>
                        <Col span={24}></Col>
                    </Row>
                </Panel>
            </Collapse>
        </Col>
    );
};


const PredictionBar = ({
    player,
    banker,
    tie
}) => {
    return (<Row align="middle" className="flex justify-center mt-3 sm:mb-4">
        <Col span={6} className="flex justify-center">
            <Avatar size="small" className="circle-bg-red text-small fw-bold me-1" style={{ border: 'none', width: 26, height: 23, lineHeight: 23, fontSize: 18 }}>B</Avatar>
            {banker}
        </Col>
        <Col span={6} className="flex justify-center">
            <Avatar size="small" className="circle-bg-blue text-small fw-bold me-1" style={{ border: 'none', width: 26, height: 23, lineHeight: 23, fontSize: 18 }}>P</Avatar>
            {player}
        </Col>
        <Col span={6} className="flex justify-center">
            <Avatar size="small" className="circle-bg-green text-small fw-bold me-1" style={{ border: 'none', width: 26, height: 23, lineHeight: 23, fontSize: 18 }}>T</Avatar>
            {tie}
        </Col>
    </Row>);
};



const processPlatforms = platforms => {
    if (!platforms) {
        return [];
    }

    // console.log("platforms", platforms);

    if (!Array.isArray(platforms)) {
        return [];
    }

    return platforms.map(platform => {
        const res = { id: platform.id, name: platform.name, alt: platform.name, status: platform.status, icon: '' }
        switch (platform.id) {
            case "EB":
                res.icon = EBIcon;
                break;
            case "BG":
                res.icon = BGIcon;
                break;
            case "AE":
                res.icon = AEIcon;
                break;
            case "DG":
                res.icon = DGIcon;
                break;
            case "AB":
                res.icon = ABIcon
            default:
                break;
        }
        return res;
    })
}

export default Dashboard;